import { AppBar, Box, Button, Container, Divider, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { useContext, useMemo, useState } from 'react';
import { Link, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import './Nav.scss';
import { PortalRoutes, AllRoutes } from '../../routing/Routes';
import useMyPortalToken from '../../hooks/useMyPortalToken';
import { Portal } from '../../models/Portal';
import Logo from './Logo';
import { NavType } from './types';
import useUser from '../../hooks/useUser';
import { MemberAssociation } from '../../models/MemberAssociation';
import { UserContext } from '../../UserContextProvider';

const _settings: NavType[] = [
  // { name: "Profile", url: "/profile" },
  // { name: "Account", url: "/account" },
  // { name: "Dashboard", url: "/dashboard" },
  { name: 'Admin Logs', url: AllRoutes.LogReport, admin: true },
  { name: 'Logout', url: AllRoutes.Logout },
];

const _portalSubItems: NavType[] = [
  { name: 'Reports', url: '' },
  { name: 'Documents', url: `${AllRoutes.Portal.Documents}` },
  { name: 'Links', url: `${AllRoutes.Portal.Links}` },
];
const _assessmentSubItems: NavType[] = [
  { name: 'Visits / Scorecards', url: `${AllRoutes.Portal.Assessments}` },
  { name: 'Administration', url: `${AllRoutes.Portal.AssessmentAdministration}`, admin: true },
];
const _eventsSubItems: NavType[] = [
  { name: 'Meetings / Events', url: `${AllRoutes.Portal.Events}` },
  { name: 'Documents', url: `${AllRoutes.Portal.Documents}` },
];

// const _videoTrainingSubItems: NavType[] = [{ name: "Video Training", url: `${AllRoutes.Portal.VideoTraining}` }];

interface TopNavProps {
  portals: Portal[];
  admin: boolean;
  setActiveMember: (member: MemberAssociation) => void;
}

export default function TopNav({ portals, admin, setActiveMember }: TopNavProps) {
  // SETUP
  const location = useLocation();
  const routeMatch = matchRoutes(PortalRoutes, location);
  const portalParam = (routeMatch ? routeMatch[0].params['portal'] ?? '' : '').toLocaleLowerCase();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElPortal, setAnchorElPortal] = useState<null | HTMLElement>(null);
  const [hoveredPortal, setHoveredPortal] = useState<string>();
  const [subItems, setSubItems] = useState(_portalSubItems);
  const { firstName, lastName, role } = useMyPortalToken();
  const { isAdmin, associations } = useUser();
  const navigate = useNavigate();
  const user = useContext(UserContext);

  // HANDLERS
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenPortalMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPortal(event.currentTarget);
  };

  const handlePortalClick = (clickedPortal: NavType) => {
    if (clickedPortal.id === 6) navigate(`${clickedPortal.url}`);
    else if (clickedPortal.id === 5) navigate(`${clickedPortal.url}/${_eventsSubItems[0].url}`);
    else if (clickedPortal.id === 0) navigate(`${clickedPortal.url}`);
    else navigate(`${clickedPortal.url}/${_portalSubItems[0].url}`);
    handleClosePortalMenu(null, true);
  };

  const handlePortalHover = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, portal: NavType, i: number) => {
    if (i > 0 && i !== 6) {
      handleOpenPortalMenu(e);
    }
    setHoveredPortal(portal.url);
    if (portal.id === 5) {
      // Meetings and Events
      setSubItems([..._eventsSubItems]);
    } else if (portal.id === 4) {
      // Underwriting
      setSubItems([{ name: 'Member Renewal Survey', url: 'https://cc-renewal-portal-webapp-prd.azurewebsites.net' }, ..._portalSubItems]);
    } else if (
      portal.id === 3 &&
      (isAdmin ||
        role?.toLowerCase() === 'ccss staff' ||
        role?.toLowerCase() === 'risk manager' ||
        role?.toLowerCase() === 'ceo' ||
        role?.toLowerCase() === 'cfo' ||
        role?.toLowerCase() === 'member coo')
    ) {
      // Risk Management
      setSubItems([..._portalSubItems.filter((p) => !p.name.includes('Reports')), ..._assessmentSubItems]);
    } else {
      setSubItems([..._portalSubItems]);
    }
  };

  const handleClosePortalMenu = (e: any, forceClose: boolean = false) => {
    if (!forceClose && e.currentTarget.localName !== 'ul') {
      const portalmenu = document.getElementById('portal-menu');
      if (portalmenu) {
        const menu = portalmenu.children[2] as HTMLElement;
        const menuBoundary = {
          left: menu.offsetLeft,
          top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
          right: menu.offsetLeft + menu.offsetWidth,
          bottom: menu.offsetTop + menu.offsetHeight,
        };
        if (e.clientX >= menuBoundary.left && e.clientX <= menuBoundary.right && e.clientY <= menuBoundary.bottom && e.clientY >= menuBoundary.top) {
          return;
        }
      }
    }
    setAnchorElPortal(null);
  };

  // USEMEMOS
  const sortedPortals = useMemo(() => {
    let newPortals: NavType[] = [{ id: 0, name: 'Home', url: AllRoutes.Home }];
    portals.forEach((p) => {
      newPortals.push({
        id: p.portalId,
        name: p.portalName!,
        url: `${AllRoutes.Portal.main}/${p.portalName!}`,
      });
    });
    return newPortals;
  }, [portals]);

  // RENDER
  return (
    <AppBar className='topnav' position='sticky' sx={{ zIndex: 1301 }}>
      <Container maxWidth='xl'>
        <Toolbar sx={{ height: '100%' }} disableGutters>
          {/* normal header logo*/}
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Logo />
          </Box>

          {/* mobile hamburger menu */}
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton size='large' aria-label='account of current user' aria-controls='menu-appbar' aria-haspopup='true' onClick={handleOpenNavMenu} color='cyan'>
              <MenuIcon />
            </IconButton>
          </Box>
          <Menu
            id='menu-appbar'
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {sortedPortals.map((portal, i) => (
              <MenuItem key={i} onClick={handleCloseNavMenu}>
                <Button
                  onClick={() => handlePortalClick(portal)}
                  style={{
                    maxWidth: 150,
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                  }}
                >
                  {portal.name}
                </Button>
              </MenuItem>
            ))}
          </Menu>

          {/* normal header links */}
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: 'none',
                md: 'flex',
                justifyContent: 'start',
                height: '100%',
                overflow: 'hidden',
              },
            }}
          >
            {sortedPortals.map((portal, i) => {
              let isChosen = portalParam === portal.name.toLocaleLowerCase();
              return (
                <Button
                  key={i}
                  aria-owns={Boolean(anchorElPortal) ? 'portal-menu' : undefined}
                  color='cyan'
                  aria-haspopup='true'
                  onMouseOver={(e) => handlePortalHover(e, portal, i)}
                  onMouseLeave={handleClosePortalMenu}
                  onClick={() => handlePortalClick(portal)}
                  sx={{
                    ml: 3,
                    pl: '6px',
                    pr: '6px',
                    zIndex: 1301,
                    backgroundColor: isChosen ? (theme) => theme.palette.cyan.main : 'initial',
                    color: 'text.primary',
                    borderRadius: 0,
                    transition: 'transform 80ms ease-in-out',
                    fontFamily: isChosen ? 'AvenirBlack' : 'AvenirBook',
                    fontWeight: 'bold',
                    ':hover': {
                      transform: 'scale(1.05)',
                      backgroundColor: isChosen ? (theme) => theme.palette.cyan.main : 'initial',
                    },
                    maxWidth: 280,
                  }}
                >
                  {portal.name}
                </Button>
              );
            })}
          </Box>

          {/* mobile header */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              ml: 3,
            }}
          >
            <Logo />
          </Box>

          {/* profile component */}
          <Button
            color='cyan'
            sx={{
              height: '100%',
              maxWidth: 200,
            }}
            onClick={(e) => {
              if (Boolean(anchorElUser)) handleCloseUserMenu();
              else handleOpenUserMenu(e);
            }}
          >
            <Box display='flex' flexDirection='column'>
              <Box
                display='flex'
                alignItems='center'
                sx={{
                  p: 0,
                  height: '100%',
                  cursor: 'pointer',
                }}
              >
                {/* <Avatar>
                  <AccountCircleIcon sx={{ height: '100%', width: '100%' }} />
                </Avatar> */}
                {/* hide name for mobile */}
                <Box
                  sx={{
                    ml: 1,
                    mr: 1,
                    display: {
                      xs: 'none',
                      sm: 'block',
                      maxWidth: 200,
                      textAlign: 'center',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '1.1em',
                      color: 'text.primary',
                      textTransform: 'capitalize',
                    }}
                  >
                    {firstName} {lastName}
                  </Typography>
                </Box>
                <ArrowDropDownRoundedIcon />
              </Box>
              <Typography
                sx={{
                  fontSize: '1em',
                  color: 'text.secondary',
                  textTransform: 'capitalize',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  maxWidth: 200,
                  textOverflow: 'ellipsis',
                  paddingRight: 2.5,
                }}
              >
                {user?.activeMember?.companyName}
              </Typography>
            </Box>
            {/* </Tooltip> */}
          </Button>

          {/* dropdown menu for user profile */}
          <Menu
            sx={{
              '& ul': {
                p: 0,
              },
            }}
            className='topnav-menu'
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {associations && associations.length > 1 && (
              <>
                {associations.map((a) => {
                  return (
                    <MenuItem
                      key={a.companyId}
                      disabled={user?.activeMember?.companyId === a.companyId}
                      data-companyid={a.companyId}
                      onClick={(e) => {
                        var c = associations.find((aa) => aa.companyId?.toString() === e.currentTarget.dataset['companyid']);
                        if (c) setActiveMember(c);
                        handleCloseUserMenu();
                      }}
                      sx={{ p: 1 }}
                    >
                      <Typography className='menuitem' sx={{ padding: '8px 14px' }}>
                        {a.companyName}
                      </Typography>
                    </MenuItem>
                  );
                })}
                <Divider style={{ marginTop: 0, marginBottom: 0 }} />
              </>
            )}

            {_settings.map((setting, i) => {
              if (admin || !setting.admin) {
                return (
                  <MenuItem key={i} onClick={handleCloseUserMenu} sx={{ p: 1 }}>
                    <Link className='menuitem' to={setting.url}>
                      {setting.name}
                    </Link>
                  </MenuItem>
                );
              } else return undefined;
            })}
          </Menu>

          {/* dropdown menu for portal */}
          <Menu
            // sx={{ mt: "45px" }}
            // className="topnav-menu"
            id='portal-menu'
            anchorEl={anchorElPortal}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(anchorElPortal)}
            MenuListProps={{ onMouseLeave: handleClosePortalMenu }}
            // sx={{
            //   "MuiList-root": {
            //     p: 0,
            //   },
            // }}
          >
            {subItems.map((p, i) => {
              if (admin || !p.admin) {
                return (
                  <MenuItem key={i} onClick={(e) => handleClosePortalMenu(e, true)} sx={{ p: 0 }}>
                    {p.url.includes('https://') ? (
                      <a className='menuitem' href={p.url!} target='_blank' rel='noopener noreferrer'>
                        {p.name}
                      </a>
                    ) : (
                      <Link className='menuitem' to={`${hoveredPortal}/${p.url!}`}>
                        {p.name}
                      </Link>
                    )}
                  </MenuItem>
                );
              } else return undefined;
            })}
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
