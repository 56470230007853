import { ApolloError, useLazyQuery } from "@apollo/client";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { RoleOutput } from "../graphQL/outputModels/Role.output";
import { QUERIES } from "../graphQL/queries";
import { Portal } from "../models/Portal";
import useMyPortalToken from "./useMyPortalToken";
import { MemberAssociation } from "../models/MemberAssociation";
import { useNavigate } from "react-router-dom";



type ret = {
    role: string;
    portals?: Portal[];
    isAdmin: boolean;
    validUser: boolean;
    userId: number;
    associations?: MemberAssociation[];
    error?: ApolloError;
    loading: boolean;
    called: boolean;
}

export default function useUser(): ret {
    const isAuthenticated = useIsAuthenticated();
    const { portalMember } = useMyPortalToken();
    const { inProgress } = useMsal();
    const authLoading = inProgress !== InteractionStatus.None;

    const [getRole, { loading, data, error, called }] =
        useLazyQuery<RoleOutput>(QUERIES.GET_ROLES);

    useEffect(() => {
        if (!loading && !data && !error && !authLoading && isAuthenticated) {
            getRole();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, data, authLoading, isAuthenticated, portalMember])

    useEffect(() => {
        if (error) {
            console.log(error);
            // navigate(AllRoutes.Forbidden);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])


    return {
        role: data?.role.roleName ?? "",
        portals: data?.role.portals,
        isAdmin: data?.role.access === "Admin",
        validUser: portalMember === "Yes",
        userId: data?.role.userId ?? 0,
        associations: data?.role.memberAssociations,
        loading: loading,
        error,
        called
    }
}